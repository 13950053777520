@import "../../styles/variables.scss";

.federations-list {
  margin-top: 16px;
  margin-bottom: 16px;

  @include for-size(lg) {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .federations-carousel {
    padding: 0 10px;
  }

  .federation {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $secondary;
    padding: 6px 12px;
    height: 42px;
    transform: skewX(-18deg);

    @include for-size(lg) {
      height: 60px;
    }

    .federation-logo {
      transform: skewX(18deg);
      max-width: 100%;
      max-height: 100%;
    }
  }
}
