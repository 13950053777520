@import "../../styles/variables.scss";

.footer {
  background-color: $dark;

  .footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;

    .logo {
      height: 32px;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 0.75;
      }
    }

    .social-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .social-link {
        display: flex;
        align-items: center;
        color: $primary;

        .icon {
          font-size: 28px;
        }
      }
    }
  }
}
