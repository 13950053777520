@import "../../styles/variables.scss";

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $base;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    animation-name: logo-animation;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
  }
}

.loader-loaded {
  transition: opacity 0.3s 0.5s, visibility 0s 0.8s;
  opacity: 0;
  visibility: hidden;
}

@keyframes logo-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
