$base: #121218;
$primary: #0eb56f;
$accent: #788ca0;
$secondary: #16161e;
$dark: #0e0e12;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin for-size($size) {
  @if $size == sm {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if $size == md {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if $size == lg {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  } @else if $size == xl {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  } @else if $size == xxl {
    @media (min-width: $breakpoint-xxl) {
      @content;
    }
  }
}
