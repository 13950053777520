@import "../../styles/variables.scss";

.main-events {
  @include for-size(xxl) {
    width: 1140px;
  }

  .inner-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 18px;
    margin: 32px 0;

    @include for-size(sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include for-size(lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .event-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      background-color: $secondary;
      color: #fff;
      font-size: 12px;
      padding: 12px 20px;

      @include for-size(md) {
        font-size: 14px;
        padding: 16px 24px;
      }

      &:first-child {
        background-color: $primary;
      }

      .logo-container,
      .data-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }

      .logo-container {
        width: 35%;
        align-items: center;
        padding-right: 12px;

        .event-logo {
          display: block;
          max-width: 100%;
        }
      }

      .data-container {
        width: 65%;
        text-align: left;
        padding-left: 12px;

        .event-name {
          font-size: 16px;
          padding-bottom: 8px;
          flex-grow: 1;

          @include for-size(md) {
            font-size: 18px;
          }
        }

        .event-date {
          padding-bottom: 6px;
        }
      }
    }
  }

  .extended {
    @include for-size(lg) {
      .event-card {
        &:first-child {
          flex-direction: column;
          grid-row-start: 1;
          grid-row-end: 3;

          .logo-container,
          .data-container {
            width: 100%;
            padding: 0;
          }

          .event-name {
            flex-grow: unset !important;
          }
        }
      }
    }
  }
}
