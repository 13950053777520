@import "../../styles/variables.scss";

.event-banner {
  position: relative;
  text-align: center;
  margin-top: 20px;

  @include for-size(sm) {
    margin-top: 30px;
  }

  .banner-img {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    max-width: 100%;
  }

  .federation-logo {
    width: 200px;

    @include for-size(sm) {
      width: 320px;
    }
  }

  .title {
    color: $primary;
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    margin: 12px 0;

    @include for-size(sm) {
      font-size: 46px;
      line-height: 46px;
    }

    @include for-size(md) {
      font-size: 72px;
      line-height: 72px;
    }
  }

  .countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .countdown-element {
      display: flex;
      align-items: center;

      .countdown-value {
        color: #fff;
        font-weight: 900;
        margin: 0 4px;
        font-size: 32px;
        width: 44px;

        @include for-size(md) {
          width: 72px;
          margin: 0 4px;
          font-size: 48px;
        }
      }

      &:first-child {
        .countdown-value {
          text-align: right;
          width: unset;
        }
      }

      .countdown-info {
        color: $accent;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0;
        font-size: 11px;

        @include for-size(sm) {
          margin: 0 4px;
          font-size: 17px;
        }
      }
    }
  }

  .event-time-info {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .event-info {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .info {
      color: $accent;
      text-align: left;
      margin: 0 12px;
      font-weight: 300;
      font-size: 13px;

      @include for-size(sm) {
        margin: 0 14px;
        font-size: 15px;
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 12px;

    .action-button {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $primary;
      border: 2px solid $primary;
      font-size: 14px;
      font-weight: 500;
      padding: 5px;
      border-radius: 24px;
      margin: 12px;
      width: 146px;

      @include for-size(sm) {
        width: 166px;
      }

      .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        margin-right: 15px;

        @include for-size(sm) {
          margin-right: 23px;
        }

        .icon {
          color: $base;
          font-size: 18px;
        }
      }
    }
  }
}
