@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;0,900;1,400;1,600;1,700;1,900&display=swap");

@import "variables";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background-color: $base;
  overflow-x: hidden;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main-container {
  flex: 1;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 4px;
}

img {
  display: block;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.text-right {
  text-align: right;
}

.font-weight-bold {
  font-weight: 700;
}

h1,
h2 {
  margin: 0;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  transition: all 0.2s ease-in-out;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 0.75;
  }
}

a {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 0.75;
  }
}

.section-header {
  position: relative;
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  font-style: italic;
  color: $accent;
  margin-bottom: 24px;

  @include for-size(lg) {
    font-size: 36px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: calc(50% - 90px);
    width: 180px;
    background-color: $primary;
    border: 1px solid $primary;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;

  @include for-size(sm) {
    width: 540px;
  }

  @include for-size(md) {
    width: 720px;
  }

  @include for-size(lg) {
    width: 960px;
  }

  @include for-size(xl) {
    width: 1140px;
  }

  @include for-size(xxl) {
    width: 1320px;
  }
}
