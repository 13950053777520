@import "../../styles/variables.scss";

.news {
  margin-bottom: 36px;

  .news-timeline {
    cursor: grab;
    margin-top: 36px;
    height: 620px;

    @include for-size(md) {
      height: 400px;
    }

    .swiper-slide {
      height: calc((100% - (64px + 18px)) / 4) !important;

      @include for-size(md) {
        height: calc((100% - (40px + 18px)) / 2) !important;
      }

      .article-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        background-color: $secondary;
        padding: 12px;

        .article-details {
          display: flex;
          flex-direction: column;
          padding-left: 12px;

          .article-title {
            color: #fff;
            font-weight: 400;
            flex: 1;
            font-size: 12px;

            @include for-size(lg) {
              font-size: 13px;
            }

            @include for-size(xxl) {
              font-size: 14px;
            }
          }

          .article-info {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            font-size: 11px;

            @include for-size(md) {
              font-size: 12px;
            }
            .article-date {
              color: $accent;
            }

            .article-ref {
              color: $primary;
              font-weight: 600;
              padding: 8px 0 0 8px;
            }
          }
        }
      }
    }

    .swiper-pagination {
      bottom: -2px;

      .swiper-pagination-bullet {
        background-color: $accent;
      }

      .swiper-pagination-bullet-active {
        background-color: $primary;
      }
    }
  }
}
