@import "../../styles/variables.scss";

.fight-card {
  margin-top: 12px;

  .section-header {
    font-size: 19px;
    margin-bottom: 36px;
    letter-spacing: 10px;
    font-style: normal;

    &::after {
      bottom: -10px;
      left: calc(50% - 60px);
      width: 120px;
    }
  }

  .fights-container {
    margin-bottom: 36px;

    @include for-size(md) {
      margin-bottom: 54px;
    }

    .fight-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 24px;
      flex-wrap: wrap;

      .fight-number {
        width: 100%;
        text-align: center;
        color: $accent;
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 8px;
        letter-spacing: 1px;
      }

      .fighter {
        text-transform: uppercase;
        width: calc(50% - 32px);

        @include for-size(md) {
          width: calc(50% - 100px);
        }

        .fighter-name {
          color: #fff;
          font-weight: 900;
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 6px;

          @include for-size(sm) {
            font-size: 21px;
            line-height: 21px;
          }

          @include for-size(md) {
            font-size: 32px;
            line-height: 32px;
          }

          @include for-size(lg) {
            margin-bottom: 10px;
            font-size: 37px;
            line-height: 37px;
          }
        }

        .fighter-subname {
          color: $accent;
          font-style: italic;
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;

          @include for-size(sm) {
            font-size: 13px;
            line-height: 13px;
          }

          @include for-size(md) {
            font-size: 14px;
            line-height: 14px;
          }

          @include for-size(lg) {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }

      .versus {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary;
        font-weight: 900;
        font-style: italic;
        font-size: 20px;
        line-height: 20px;
        width: 64px;

        @include for-size(sm) {
          font-size: 21px;
          line-height: 21px;
        }

        @include for-size(md) {
          align-items: flex-start;
          font-size: 37px;
          line-height: 37px;
          width: 200px;
        }
      }

      .fight-info {
        width: 100%;
        margin-top: 8px;

        @include for-size(md) {
          margin-top: -14px;
        }

        .info {
          display: flex;
          flex-direction: row;
          justify-content: center;
          font-size: 11px;
          text-transform: uppercase;

          .info-data {
            color: #fff;
            white-space: nowrap;
            width: 100%;

            &:first-of-type {
              text-align: right;
            }
          }

          .info-description {
            color: $accent;
            text-align: center;
            min-width: 64px;

            @include for-size(md) {
              min-width: 80px;
            }
          }
        }
      }
    }
  }
}
