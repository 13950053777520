@import "../../styles/variables.scss";

.event-ref {
  visibility: hidden;
  scroll-margin-top: 12px;
}

.main-events-btn {
  display: block;
  margin: 0 auto 24px auto;
  text-align: center;
  padding: 12px 24px;
  max-width: 100%;
  color: $accent;
  text-transform: uppercase;
  text-decoration: none !important;
  font-size: 14px;
  border: 2px solid $accent;
  border-radius: 24px;
  transition: all 0.2s ease-in-out;

  .button-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 21px;
    overflow: hidden;

    .icon {
      height: 21px;
    }

    span,
    svg {
      transition: transform 0.2s ease-in-out;
    }
  }

  &:hover {
    color: #fff;
    border-color: #fff;

    .button-inner {
      span,
      svg {
        transform: translateY(21px);
      }
    }
  }
}
