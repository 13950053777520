@import "../../styles/variables.scss";

.calendar {
  margin-bottom: 36px;

  @include for-size(md) {
    margin-bottom: 54px;
  }

  .calendar-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .arrow-btn {
      color: $accent;
      padding: 2px 6px;
      font-size: 18px;

      @include for-size(sm) {
        font-size: 22px;
      }
    }

    .today-btn {
      background-color: $primary;
      font-size: 10px;
      font-weight: 600;
      color: $base;
      padding: 6px 12px;
      margin: 0 12px 0 8px;

      @include for-size(sm) {
        font-size: 12px;
      }
    }

    .date {
      color: $accent;
      font-size: 20px;
      font-weight: 700;

      @include for-size(sm) {
        font-size: 22px;
      }
    }
  }

  .days-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 4px;
    text-align: center;
    color: $accent;
    font-size: 12px;
    margin-bottom: 12px;

    @include for-size(lg) {
      gap: 16px;
    }

    .day-full {
      display: none;

      @include for-size(md) {
        display: block;
      }
    }

    .day-shortcut {
      font-weight: 600;

      @include for-size(md) {
        display: none;
      }
    }
  }

  .calendar-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 4px;

    @include for-size(lg) {
      gap: 16px;
    }

    .calendar-day {
      background-color: $secondary;
      padding: 6px;
      color: $accent;
      min-height: 42px;

      @include for-size(sm) {
        min-height: 80px;
      }

      @include for-size(md) {
        min-height: 106px;
        padding: 8px;
      }

      @include for-size(lg) {
        min-height: 120px;
        padding: 12px;
      }

      .day-indicator {
        font-size: 10px;
        font-weight: 700;
        text-align: center;

        @include for-size(sm) {
          text-align: left;
          font-size: 13px;
        }

        @include for-size(md) {
          font-size: 20px;
        }
      }

      .day-info {
        display: none;
        font-size: 8px;
        padding: 6px 0;

        @include for-size(sm) {
          display: block;
        }

        @include for-size(md) {
          font-size: 10px;
        }

        @include for-size(lg) {
          font-size: 12px;
        }
      }

      .event-logo {
        max-width: 100%;
        opacity: 0.65;

        @include for-size(sm) {
          display: none;
        }

        &.primary {
          opacity: 1;
        }
      }

      .primary {
        color: $primary;
      }
    }

    .other-month {
      background-color: $dark;
    }
  }
}
