@import "../../styles/variables.scss";

.banner {
  text-align: center;

  .banner-img {
    max-width: 100%;
  }

  .title,
  .subtitle {
    font-style: italic;
  }

  .title {
    color: $primary;
    font-weight: 900;
    font-size: 26px;
    margin-top: -36px;

    @include for-size(sm) {
      font-size: 44px;
      margin-top: -60px;
    }

    @include for-size(md) {
      font-size: 60px;
      margin-top: -86px;
    }

    @include for-size(lg) {
      font-size: 74px;
      margin-top: -104px;
    }
  }

  .subtitle {
    color: #fff;
    font-weight: 400;
    font-size: 12px;

    @include for-size(sm) {
      font-size: 14px;
      margin-top: -8px;
    }

    @include for-size(md) {
      font-size: 17px;
      margin-top: -14px;
    }

    @include for-size(lg) {
      font-size: 19px;
      margin-top: -16px;
    }
  }
}
