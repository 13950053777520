@import "../../styles/variables.scss";

.timeline {
  margin-top: 36px;
  margin-bottom: 36px;

  .events-timeline {
    height: 254px;
    cursor: grab;

    @include for-size(sm) {
      height: 288px;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      background-color: #fff;
      border: 1px solid #fff;
      width: 100%;
    }

    .swiper-slide {
      position: relative;
      background-color: $dark;
      text-align: center;
      font-size: 10px;
      font-weight: 700;
      color: #fff;
      padding: 8px;
      border-radius: 6px;
      height: calc((100% - 72px) / 2) !important;

      @include for-size(sm) {
        padding: 11px;
        font-size: 11px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        left: calc(50% - 6px);
      }

      &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        border: 2px solid #fff;
        border-radius: 50%;
        background-color: $base;
        left: calc(50% - 18px);
      }

      &:nth-child(odd) {
        &::before {
          top: 100%;
          border-top: 6px solid $dark;
        }

        &::after {
          top: calc(100% + 18px);
        }
      }

      &:nth-child(even) {
        margin-top: 72px !important;
        transform: translateX(calc(50% + 20px));

        &::before {
          bottom: 100%;
          border-bottom: 6px solid $dark;
        }

        &::after {
          bottom: calc(100% + 18px) !important;
        }
      }

      .confederation-name {
        text-transform: uppercase;
      }

      .event-date {
        color: $accent;
        white-space: nowrap;
      }

      .event-name {
        color: #fff;
        font-weight: 400;
      }
    }

    .main-event:not(.expired-event):not(.primary-event) {
      .event-date {
        color: $primary;
      }
    }

    .expired-event {
      color: $accent;

      .event-name {
        color: $accent;
      }
    }

    .primary-event {
      background-color: $primary;

      .event-date {
        color: $base;
      }

      &::before {
        border-top-color: $primary !important;
        border-bottom-color: $primary !important;
      }

      &::after {
        background-color: $primary;
      }
    }
  }

  .extended-timeline {
    @include for-size(xl) {
      padding-right: 103px;
    }
  }
}

.timeline.container {
  padding-left: 3px;
  padding-right: 3px;

  @include for-size(sm) {
    padding-left: 12px;
    padding-right: 12px;
  }
}
