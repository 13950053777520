@import "../../styles/variables.scss";

.header {
  background-color: $base;

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;

    .logo {
      height: 32px;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 0.75;
      }
    }

    .instagram-link {
      display: flex;
      align-items: center;
      color: $primary;

      @include for-size(sm) {
        border: 1px solid $primary;
        padding: 3px 12px;
        border-radius: 16px;
      }

      .subscribe-us {
        display: none;
        font-size: 13px;
        margin-right: 6px;

        @include for-size(sm) {
          display: block;
        }
      }

      .icon {
        font-size: 28px;

        @include for-size(sm) {
          font-size: 20px;
        }
      }
    }
  }
}
